var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"mt-7",style:('min-width: 100% !important'),attrs:{"id":"tableTattooerWeek"}},[_c('tr',[_c('th',{attrs:{"colspan":"1"}}),_c('th',{attrs:{"colspan":"1"}}),_vm._l((_vm.daysWeek),function(day){return _c('th',{key:day,staticStyle:{"cursor":"auto","text-transform":"capitalize"},attrs:{"colspan":_vm.boxes.length}},[_c('div',{staticStyle:{"cursor":"default","display":"flex","align-items":"center","flex-direction":"column"},style:([
          /*{
            'background-color':
              $store.getters['auth/isTattooer'] &&
              tattooerHoursWeek(h, box, day).reserva &&
              tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                $store.state.auth.user.tattooer.id
                ? 'var(--v-primary-base)'
                : tattooerHoursWeek(h, box, day).color,
          },*/
        ]),on:{"click":function($event){_vm.filterTattooer(_vm.tattooerHoursWeek(_vm.h, _vm.box, day).reserva.tattooer.id)}}},[_c('div',{staticClass:"pa-0 rounded-pill",staticStyle:{"background-color":"var(--v-primary-base)","width":"90% !important","height":"30px","color":"var(--v-gris3-base)","font-size":"12px","font-weight":"200","line-height":"30px","cursor":"default"}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "weekDay"))+" ")]),(day != _vm.today)?_c('div',{staticClass:"my-2 mt-3 rounded-circle",staticStyle:{"border":"1px solid var(--v-primary-base)","height":"45px","width":"45px","line-height":"43px","color":"var(--v-primary-base)","font-weight":"400","font-size":"25px","cursor":"pointer"},on:{"click":function($event){_vm.btn = 'day';
            _vm.date = day;}}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "day"))+" ")]):_c('div',{staticClass:"my-2 mt-3 rounded-circle",staticStyle:{"background-color":"var(--v-primary-base)","height":"45px","width":"45px","line-height":"45px","color":"var(--v-gris3-base)","font-weight":"400","font-size":"25px","cursor":"pointer"},on:{"click":function($event){_vm.btn = 'day';
            _vm.date = day;}}},[_vm._v(" "+_vm._s(_vm.$d(new Date(day), "day"))+" ")])])])})],2),_c('tr',[_c('th',{attrs:{"colspan":"1"}}),_c('th',{attrs:{"colspan":"1"}}),_vm._l((_vm.daysWeek),function(day,i){return [_vm._l((_vm.boxes),function(box,j){return [_c('th',{key:(i + "-" + j),style:(_vm.boxes.length - 1 == j
              ? 'border-right: 1px solid var(--v-primary-base)'
              : '')},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"display":"flex","justify-content":"center"}},'div',attrs,false),on),[_c('span',{staticStyle:{"letter-spacing":"0px","font-size":"8px"}},[_vm._v(_vm._s(box.order_by)+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(box.name))])])],1)]})]})],2),_vm._l((_vm.hours),function(h,i){return _c('tr',{key:h},[(_vm.hours.length > i + 1)?_c('td',{style:(h.indexOf(':00') === -1 ? 'visibility: hidden; border-top:0px' : '')},[_c('span',[_vm._v(_vm._s(h))])]):_c('td',{},[_c('span',[_vm._v(_vm._s(+_vm.hours[i].split(":")[0])+":"+_vm._s(_vm.hours[i].split(":")[1]))])]),_c('td',{style:(h.indexOf(':00') === -1 ? ' border-top:0px' : ''),attrs:{"colspan":"1","width":"1px"}}),_vm._l((_vm.daysWeek),function(day,i){return [_vm._l((_vm.boxes),function(box,j){return [(
            _vm.tattooerHoursWeek(h, box, day).reserva &&
            _vm.tattooerHoursWeek(h, box, day).colspan > 0
          )?_c('td',{key:(i + "-" + j),staticStyle:{"cursor":"default","border":"2px solid var(--v-primary-base)"},style:([
            {
              'background-color':
                _vm.$store.getters['auth/isTattooer'] &&
                _vm.tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                  _vm.$store.state.auth.user.tattooer.id
                  ? 'var(--v-primary-base)'
                  : _vm.tattooerHoursWeek(h, box, day).color,
            } ]),attrs:{"rowspan":_vm.tattooerHoursWeek(h, box, day).colspan},on:{"click":function($event){_vm.filterTattooer(_vm.tattooerHoursWeek(h, box, day).reserva.tattooer.id)}}},[(
              !(
                _vm.$store.getters['auth/isTattooer'] &&
                _vm.tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                  _vm.$store.state.auth.user.tattooer.id
              )
            )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-0 ma-0",staticStyle:{"height":"100%","width":"100%","cursor":"pointer"}},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tattooerHoursWeek(h, box, day).reserva.tattooer.user .first_name)+" "+_vm._s(_vm.tattooerHoursWeek(h, box, day).reserva.tattooer.user .last_name)+" ")])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-0 ma-0",staticStyle:{"height":"100%","width":"100%","cursor":"default"}},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" Ocupado ")])])]],2):(_vm.rowspanTattooerWeek(h, box, day))?_c('td',{key:(i + "-" + j),class:[
            {
              gris2: _vm.checkTime()(
                h,
                day,
                _vm.$store.getters['auth/isStudio']
                  ? _vm.$store.state.auth.user.studio.id
                  : _vm.$store.state.boxes.studioId,
                _vm.$store.getters['auth/isStudio']
                  ? _vm.filtersWeek.tattooer
                  : _vm.$store.state.auth.user.tattooer.id,
                box.id
              ),
            } ],style:(h.indexOf(':00') === -1 ? ' border-top:0px' : '')}):_vm._e()]})]})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }