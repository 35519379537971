<template>
  <v-simple-table
    id="tableTattooerWeek"
    class="mt-7"
    :style="'min-width: 100% !important'"
  >
    <tr>
      <th colspan="1"></th>
      <th colspan="1"></th>
      <th
        :key="day"
        v-for="day in daysWeek"
        style="cursor: auto; text-transform: capitalize"
        :colspan="boxes.length"
      >
        <div
          style="
            cursor: default;

            display: flex;
            align-items: center;
            flex-direction: column;
          "
          v-bind:style="[
            /*{
              'background-color':
                $store.getters['auth/isTattooer'] &&
                tattooerHoursWeek(h, box, day).reserva &&
                tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                  $store.state.auth.user.tattooer.id
                  ? 'var(--v-primary-base)'
                  : tattooerHoursWeek(h, box, day).color,
            },*/
          ]"
          @click="
            filterTattooer(tattooerHoursWeek(h, box, day).reserva.tattooer.id)
          "
        >
          <div
            class="pa-0 rounded-pill"
            style="
              background-color: var(--v-primary-base);
              width: 90% !important;
              height: 30px;
              color: var(--v-gris3-base);
              font-size: 12px;
              font-weight: 200;
              line-height: 30px;
              cursor: default;
            "
          >
            {{ $d(new Date(day), "weekDay") }}
          </div>
          <div
            v-if="day != today"
            class="my-2 mt-3 rounded-circle"
            style="
              border: 1px solid var(--v-primary-base);
              height: 45px;
              width: 45px;
              line-height: 43px;
              color: var(--v-primary-base);
              font-weight: 400;
              font-size: 25px;
              cursor: pointer;
            "
            @click="
              btn = 'day';
              date = day;
            "
          >
            {{ $d(new Date(day), "day") }}
          </div>
          <div
            v-else
            class="my-2 mt-3 rounded-circle"
            style="
              background-color: var(--v-primary-base);
              height: 45px;
              width: 45px;
              line-height: 45px;
              color: var(--v-gris3-base);

              font-weight: 400;
              font-size: 25px;
              cursor: pointer;
            "
            @click="
              btn = 'day';
              date = day;
            "
          >
            {{ $d(new Date(day), "day") }}
          </div>
        </div>
      </th>
    </tr>
    <tr>
      <th colspan="1"></th>
      <th colspan="1"></th>

      <template v-for="(day, i) in daysWeek">
        <template v-for="(box, j) in boxes">
          <th
            :key="`${i}-${j}`"
            :style="
              boxes.length - 1 == j
                ? 'border-right: 1px solid var(--v-primary-base)'
                : ''
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  style="display: flex; justify-content: center"
                  v-on="on"
                  v-bind="attrs"
                >
                  <span style="letter-spacing: 0px; font-size: 8px"
                    >{{ box.order_by }}
                  </span>
                </div>
              </template>
              <span>{{ box.name }}</span>
            </v-tooltip>
          </th>
        </template>
      </template>
    </tr>
    <tr v-for="(h, i) in hours" :key="h">
      <td
        v-if="hours.length > i + 1"
        :style="
          h.indexOf(':00') === -1 ? 'visibility: hidden; border-top:0px' : ''
        "
      >
        <span>{{ h }}</span>
      </td>

      <td v-else style="">
        <span>{{ +hours[i].split(":")[0] }}:{{ hours[i].split(":")[1] }}</span>
      </td>
      <td
        colspan="1"
        width="1px"
        :style="h.indexOf(':00') === -1 ? ' border-top:0px' : ''"
      ></td>

      <template v-for="(day, i) in daysWeek">
        <template v-for="(box, j) in boxes">
          <td
            :rowspan="tattooerHoursWeek(h, box, day).colspan"
            :key="`${i}-${j}`"
            v-if="
              tattooerHoursWeek(h, box, day).reserva &&
              tattooerHoursWeek(h, box, day).colspan > 0
            "
            style="cursor: default; border: 2px solid var(--v-primary-base)"
            v-bind:style="[
              {
                'background-color':
                  $store.getters['auth/isTattooer'] &&
                  tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                    $store.state.auth.user.tattooer.id
                    ? 'var(--v-primary-base)'
                    : tattooerHoursWeek(h, box, day).color,
              },
            ]"
            @click="
              filterTattooer(tattooerHoursWeek(h, box, day).reserva.tattooer.id)
            "
          >
            <template
              v-if="
                !(
                  $store.getters['auth/isTattooer'] &&
                  tattooerHoursWeek(h, box, day).reserva.tattooer.id !==
                    $store.state.auth.user.tattooer.id
                )
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="pa-0 ma-0"
                    v-on="on"
                    v-bind="attrs"
                    style="height: 100%; width: 100%; cursor: pointer"
                  ></div>
                </template>
                <span
                  >{{
                    tattooerHoursWeek(h, box, day).reserva.tattooer.user
                      .first_name
                  }}
                  {{
                    tattooerHoursWeek(h, box, day).reserva.tattooer.user
                      .last_name
                  }}
                </span>
              </v-tooltip>
            </template>
            <template v-else
              ><v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="pa-0 ma-0"
                    v-on="on"
                    v-bind="attrs"
                    style="height: 100%; width: 100%; cursor: default"
                  ></div>
                </template>
                <span> Ocupado </span>
              </v-tooltip>
            </template>
          </td>
          <td
            :key="`${i}-${j}`"
            v-else-if="rowspanTattooerWeek(h, box, day)"
            :style="h.indexOf(':00') === -1 ? ' border-top:0px' : ''"
            v-bind:class="[
              {
                gris2: checkTime()(
                  h,
                  day,
                  $store.getters['auth/isStudio']
                    ? $store.state.auth.user.studio.id
                    : $store.state.boxes.studioId,
                  $store.getters['auth/isStudio']
                    ? filtersWeek.tattooer
                    : $store.state.auth.user.tattooer.id,
                  box.id
                ),
              },
            ]"
          ></td>
        </template>
      </template>
    </tr>
  </v-simple-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "CalendarWeekGeneral",
  data() {
    return {
      boxWeek: [],

      boxWeekTotal: null,
      boxWeekHours: [],
    };
  },

  watch: {
    filtersState: {
      handler() {
        this.fetchWeek();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("boxes", [
      "dateState",
      "tabs",
      "filtersState",
      "boxes",
      "tattooers",
      "filtersWeekState",
      "dateState",
    ]),
    ...mapGetters("boxes", [
      "hoursRow",
      "timeTo",
      "timeFrom",
      "mins",
      "today",
      "daysWeek",
    ]),
    ...mapGetters("timetables", [
      "hours",
      "firstHourGlobal",
      "lastHourGlobal",
      "mins",
    ]),
    filtersWeek: {
      get() {
        return this.filtersWeekState;
      },
      set(value) {
        this.$store.commit("boxes/SET_FILTERS_WEEK", value);
      },
    },

    btn: {
      get() {
        return this.tabs.btn;
      },
      set(value) {
        this.$store.commit("boxes/SET_TABS", { value, tab: "btn" });
      },
    },
    date: {
      get() {
        return this.dateState;
      },
      set(value) {
        this.$store.commit("boxes/SET_DATE", value);
      },
    },
  },
  mounted() {
    this.fetchWeek();
  },
  methods: {
    ...mapGetters("timetables", ["checkTime"]),
    tattooerHoursWeek(h, box, day) {
      let c = this.boxWeek[day];

      if (c) {
        let hs = c.find((x) => {
          return h == x.start_time && x.box_id == box.id && day == x.start_date;
        });
        // //console.log(hs);
        if (hs) {
          let span = 0;
          let end = parseInt(hs.end_time);
          let start = parseInt(hs.start_time);
          for (let x = start; x <= end; x++) {
            this.mins.forEach((y) => {
              let t = `${x < 10 ? "0" + x : x}${y}`;
              //console.log(t);
              if (hs.start_time <= t && hs.end_time > t) span++;
            });
          }
          //console.log("box", hs);

          return { reserva: hs, colspan: span, color: hs.tattooer.color };
        }
      }

      return { reserva: null, colspan: 1 };
    },

    rowspanTattooerWeek(h, box, day) {
      let c = this.boxWeek[day];
      if (c) {
        let hs = c.find((x) => {
          return h > x.start_time && h < x.end_time && x.box_id == box.id;
        });
        ////console.log(n.boxes_tattooer[0].start_time == h);

        if (hs) {
          return false;
        }
      }
      return true;
    },
    ...mapActions("boxes", ["showTablePercentage"]),
    fetchWeek() {
      this.loading = true;

      this.showTablePercentage({
        dates: { from: this.filtersState.from, to: this.filtersState.to },
        studio: this.$store.state.boxes.studioId,
      }).then((boxes) => {
        this.boxWeek = boxes.data;
        this.boxWeekTotal = boxes.total;
        this.loading = false;
      });
    },

    filterTattooer(tattooer_id) {
      if (!this.$store.getters["auth/isTattooer"]) {
        let t = this.tattooers.find((x) => x.id === tattooer_id);
        if (t) {
          this.filtersWeek.tattooer = tattooer_id;
        }
      }
    },
  },
};
</script>

<style lang="sass" scoped>
#tableTattooerWeek
  margin-bottom: 20px !important
  min-width: 1000px
  padding-left: 60px
  width: auto
  background-color: var(--v-gris3-base)
  tr:nth-child(2) th:nth-child(3)
    border-left: 1px solid var(--v-primary-base)
  tr:first-child th
    border: 0
    font-size: 16px !important
  tr:nth-child(2) th
    border: 0

  td:first-child, th:first-child
    border-left: 0
    border-top: 0
    border-right: 0
    min-width: 50px
  tr:last-child td:first-child
    border-bottom: 0
    span
      margin-top: -4px !important
  td:first-child,th:first-child
    width: 50px
    position: absolute
  tr:last-child
    td
      height: 0
      max-height: 0
  td
    height: 12px
  span
    display: block
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
  td:first-child span
    position: absolute
    margin-top: -10px
    margin-left: -50px
  tr, td:first-child, th:first-child
    border-left: 0
    border-right: 0

  tr:last-child td
    border-bottom: 1px solid var(--v-primary-base)
  td, th
    border-top: 1px solid var(--v-primary-base)
    border-right: 1px solid var(--v-primary-base)
    border-color: var(--v-primary-base) !important

    padding: 0 !important

    min-width: 22px
    max-width: 22px
    text-align: center
  th
    padding: 4px !important
  td:nth-child(2), th:nth-child(2)
    min-width: 20px !important
    width: 20px
    border-left: 0
    border-bottom: 0
    border-top: 1px solid var(--v-primary-base)
</style>
